import React, { useRef, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { GameStatus } from "../../client/server-types-python";
import { InterfaceButton } from "./InterfaceButton";
import { UserContext } from "../../contexts/UserContext";
import { UiSettingsContext } from "../../model/UiSettings";
import { TableContext } from "../TableContext";

const DropdownButton = styled(InterfaceButton)`
    position: relative;
    display: inline-block;
    margin: 0px 0px 0px 6px;
    border-radius: 6px;
`;

const DropdownMenuParent = styled.div`
    position: relative;
    display: inline-block;
`;

const GameDropdownDiv = styled.div`
    display: block;
    position: absolute;
    right: 0px;
    top: 100%;
    min-width: 140px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
`;

const DropdownItemButton = styled.button`
    display: block;
    width: 100%;
    padding: 12px 16px;
    text-decoration: none;
    background-color: ${(props) => props.theme.colors.primary_hinted_white};
    color: ${(props) => props.theme.colors.black_text};
    text-align: left;
    border: none;

    &:hover {
        background-color: ${(props) => props.theme.colors.primary_very_light};
    }
    &:disabled {
        color: ${(props) => props.theme.colors.black_text_disabled};
    }
`;

const PrivilegedDropdownItemButton = styled.button`
    display: block;
    width: 100%;
    padding: 12px 16px;
    text-decoration: none;
    background-color: ${(props) => props.theme.colors.privileged_light};
    color: ${(props) => props.theme.colors.black_text};
    text-align: left;
    border: none;

    &:hover {
        background-color: ${(props) => props.theme.colors.privileged_medium};
    }
`;

interface GameDropdownProps {
    leaveTable: () => void;
    requestAbort: () => void;
    requestUndoFunc?: () => void;
    gameStatus: GameStatus;
}

export const GameDropdown: React.FC<GameDropdownProps> = ({ leaveTable, requestAbort, requestUndoFunc, gameStatus }) => {
    const tableContext = useContext(TableContext);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

    const userContext = useContext(UserContext);
    const isPrivileged = userContext && userContext.user.privilege_level === 2;

    const { uiSettings, setUiSettings } = useContext(UiSettingsContext);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const endGameEarly = () => {
        requestAbort();
        setDropdownOpen(false);
    };

    const toggleShowBotPanel = () => {
        setUiSettings((prevState) => ({ ...prevState, showBotPanel: !prevState.showBotPanel }));
        setDropdownOpen(false);
    };
    const toggleRevealPlayerHands = () => {
        setUiSettings((prevState) => ({ ...prevState, revealPlayerHands: !prevState.revealPlayerHands }));
        setDropdownOpen(false);
    };

    const isAnotherUserInGame = tableContext?.table.game.participants.some(
        (participant) => participant && participant.user && participant.user.id !== userContext?.user.id
    );
    const isGameComplete = gameStatus === GameStatus.COMPLETE;

    return (
        <DropdownMenuParent ref={dropdownRef}>
            <DropdownButton onClick={toggleDropdown}>☰</DropdownButton>
            {dropdownOpen && (
                <GameDropdownDiv>
                    <DropdownItemButton onClick={requestUndoFunc} disabled={!requestUndoFunc}>
                        {tableContext?.replayState.replayModeEnabled ? "Undo to here" : "Undo my move"}
                    </DropdownItemButton>
                    {isAnotherUserInGame ? (
                        isGameComplete ? (
                            <DropdownItemButton onClick={leaveTable}>Leave table</DropdownItemButton>
                        ) : (
                            <DropdownItemButton onClick={leaveTable}>Leave game</DropdownItemButton>
                        )
                    ) : isGameComplete ? (
                        <DropdownItemButton onClick={leaveTable}>Close table</DropdownItemButton>
                    ) : (
                        <DropdownItemButton onClick={leaveTable}>Abandon game</DropdownItemButton>
                    )}
                    <DropdownItemButton disabled={isGameComplete} onClick={endGameEarly}>
                        End game early
                    </DropdownItemButton>
                    {isPrivileged && (
                        <>
                            <PrivilegedDropdownItemButton onClick={toggleShowBotPanel}>
                                {uiSettings.showBotPanel ? "☑ " : "☐ "}Bot Panel
                            </PrivilegedDropdownItemButton>
                            <PrivilegedDropdownItemButton onClick={toggleRevealPlayerHands}>
                                {uiSettings.revealPlayerHands ? "☑ " : "☐ "}Reveal Hands
                            </PrivilegedDropdownItemButton>
                        </>
                    )}
                </GameDropdownDiv>
            )}
        </DropdownMenuParent>
    );
};
