import { CardDetails } from "../../model/CardDetails";
import { Card } from "./Card";
import { CardId } from "../../client/basic-types";
import "../../styles/game.css";
import { useMemo } from "react";

interface CardGridProps {
    cardIds: Set<CardId>;
}

export const CardGrid = ({ cardIds }: CardGridProps): JSX.Element => {
    const cardDetailRows = useMemo(() => {
        const cardIdRows = [
            [2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
            [3, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29],
            [0, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42],
            [1, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55],
        ];
        return cardIdRows.map((row) => row.map((cardId) => CardDetails.getCardDetails(cardId)));
    }, []);

    return (
        <div className="cardGrid">
            {cardDetailRows.map((row, rowIndex) => (
                <div key={rowIndex} className="cardGridRow">
                    {row.map((cardDetails) => (
                        <div key={cardDetails.cardId} className="cardGridCell">
                            <div className={cardIds.has(cardDetails.cardId) ? "unfaded" : "faded"}>
                                <Card cardDetails={cardDetails} textOnly={false} small={true} />
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
