import React from "react";
import { DmGameParticipant, DmPosition } from "../../client/server-types-python";
import styled from "styled-components";
import { getName } from "../../client/util";
import theme from "../../theme";
import { getAvatarUrl, getBotAvatarUrl } from "../../misc/Avatars";
import { ReactComponent as TBetSvg } from "../../assets/t-gear.svg";
import { ReactComponent as GBetSvg } from "../../assets/g-gear.svg";
import { CardDetails } from "../../model/CardDetails";

export enum Alignment {
    BOTTOM = "bottom",
    LEFT = "left",
    TOP = "top",
    RIGHT = "right",
}

const TBetIcon = styled(TBetSvg)`
    width: 26px;
    height: 26px;
    margin-left: -10px;
    margin-right: 2px;
    color: gold;
`;

const FailedTBetIcon = styled(TBetIcon)`
    color: white;
    opacity: 0.8;
`;

const GBetIcon = styled(GBetSvg)`
    width: 26px;
    height: 26px;
    margin-left: -10px;
    margin-right: 2px;
    color: gold;
`;

const FailedGBetIcon = styled(GBetIcon)`
    color: white;
    opacity: 0.8;
`;

const InfoBarItem = styled.div<{ $teamColor: string; $isRightEnd: boolean }>`
    background-color: ${(props) => props.$teamColor};
    height: 28px;
    padding: 4px;
    font-size: 16px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 4px; // This helps it look reasonable when trick points are off.
    ${(props) => (props.$isRightEnd ? "border-top-right-radius: 8px; border-bottom-right-radius: 8px;" : "")}
`;

const ScoreDiv = styled.div`
    padding: 0px 6px;
    font-size: 15px;
    color: white;
    width: 35px;
`;

const FinishedOrderDiv = styled.div<{ $teamColor: string }>`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: white;
    opacity: 0.8;
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.$teamColor};
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1px;
`;

const BetSucceededFinishedOrderDiv = styled(FinishedOrderDiv)`
    background-color: gold;
    opacity: 1;
    box-shadow: 0 0 0 4px #ffd85655;
    width: 23px;
    height: 23px;
    margin: 2px;
`;

const SeatDiv = styled.div<{ $alignment: Alignment }>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0px solid black;
    margin: 12px;
    border-radius: 8px;
    font-size: 36px;
    color: white;
    ${(props) => {
        switch (props.$alignment) {
            case Alignment.BOTTOM:
                return "width: 514px; height: 122px;";
            case Alignment.LEFT:
                return "height: 514px; width: 122px; flex-direction: row-reverse;";
            case Alignment.TOP:
                return "width: 514px; height: 122px;";
            case Alignment.RIGHT:
                return "height: 514px; width: 122px; flex-direction: row;";
        }
    }}
`;

const PlayerInfoWrapper = styled.div<{ $alignment: Alignment }>`
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensure content stays within bounds */
    ${(props) => {
        switch (props.$alignment) {
            case Alignment.BOTTOM:
            case Alignment.TOP:
                return "width: 100%; height: 60px;";
            case Alignment.LEFT:
            case Alignment.RIGHT:
                return "height: 100%; width: 60px;";
        }
    }}
`;

const AvatarDiv = styled.div<{ $teamColor: string }>`
    width: 36px;
    height: 36px;
    background-color: ${(props) => props.$teamColor};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const AvatarImage = styled.img`
    width: 80%;
    height: 80%;
    object-fit: cover;
    opacity: 0.8;
`;

const PlayerInfoDiv = styled.div<{ $active: boolean; $alignment: Alignment; $teamColor: string }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0px solid black;
    margin: 12px;
    border-radius: 8px;
    font-size: 36px;
    color: white;
    background-color: ${(props) => props.$teamColor};
    padding: 0px;

    // add a glow around it if active
    ${(props) => (props.$active ? "box-shadow: 0 0 8px 5px #ffd856;" : "")}
    ${(props) => {
        switch (props.$alignment) {
            case Alignment.BOTTOM:
                return "";
            case Alignment.LEFT:
                return "transform: rotate(90deg);";
            case Alignment.TOP:
                return "";
            case Alignment.RIGHT:
                return "transform: rotate(-90deg);";
        }
    }}
`;

const PlayerInfoLabel = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0px solid black;
    font-size: 20px;
    color: black;
    white-space: nowrap;
`;

const PlayerInfoText = styled.span`
    margin: 0px 18px;
`;

const SitHereButton = styled.button`
    border: none;
    background: ${theme.colors.action};
    color: ${theme.colors.black_text};
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 4px;
    margin-bottom: 4px;

    &:hover {
        background: ${theme.colors.action_hover};
    }
`;

const LimboCardsDiv = styled.div`
    position: absolute;
    top: -89px;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 12px;
    font-size: 36px;
    color: white;
`;

export class Seat extends React.Component<{
    player: DmGameParticipant | null;
    position: DmPosition;
    active: boolean;
    alignment: Alignment;
    hand: React.ReactNode;
    limboCards?: React.ReactNode;
    nsTeam: boolean;
    joinInSeat: () => void;
    failedBet: boolean;
    showTrickPoints: boolean;
}> {
    render() {
        const { player, position, active, alignment, hand, limboCards, nsTeam, failedBet, showTrickPoints } = this.props;
        const teamColor = nsTeam ? theme.colors.ns_color : theme.colors.ew_color;
        const teamColorLight = nsTeam ? theme.colors.ns_color_light : theme.colors.ew_color_light;
        const avatarUrl = player?.user?.avatar ? getAvatarUrl("", player.user.avatar) : getBotAvatarUrl("");

        const activeBet = position.bet;
        const activeGrandBet = position.grand_bet;
        const activeNormalBet = activeBet && !activeGrandBet;
        const finishedOrder = position.finished_order;

        const trickPoints = !showTrickPoints
            ? null
            : position.trick_cards.map((card) => CardDetails.getCardDetails(card).points).reduce((a, b) => a + b, 0);

        return (
            <SeatDiv $alignment={alignment}>
                {limboCards && <LimboCardsDiv>{limboCards}</LimboCardsDiv>}
                {alignment === Alignment.TOP && hand}
                <PlayerInfoWrapper $alignment={alignment}>
                    <PlayerInfoDiv $active={active} $alignment={alignment} $teamColor={teamColorLight}>
                        <AvatarDiv $teamColor={teamColor}>
                            <AvatarImage src={avatarUrl} alt="Player avatar" />
                        </AvatarDiv>
                        <PlayerInfoLabel>
                            <PlayerInfoText>
                                {player ? getName(player) : <SitHereButton onClick={this.props.joinInSeat}>Sit Here</SitHereButton>}
                            </PlayerInfoText>
                            <InfoBarItem $teamColor={teamColor} $isRightEnd={!activeBet && finishedOrder === 0}>
                                {showTrickPoints ? <ScoreDiv>{trickPoints + "◈"}</ScoreDiv> : null}
                            </InfoBarItem>
                            {activeNormalBet ? (
                                <InfoBarItem $teamColor={teamColor} $isRightEnd={finishedOrder === 0}>
                                    {failedBet ? <FailedTBetIcon /> : <TBetIcon />}
                                </InfoBarItem>
                            ) : null}
                            {activeGrandBet ? (
                                <InfoBarItem $teamColor={teamColor} $isRightEnd={finishedOrder === 0}>
                                    {failedBet ? <FailedGBetIcon /> : <GBetIcon />}
                                </InfoBarItem>
                            ) : null}
                            {finishedOrder ? (
                                <InfoBarItem $teamColor={teamColorLight} $isRightEnd={true}>
                                    {activeBet && finishedOrder === 1 ? (
                                        <BetSucceededFinishedOrderDiv $teamColor={teamColor}>{finishedOrder}</BetSucceededFinishedOrderDiv>
                                    ) : (
                                        <FinishedOrderDiv $teamColor={teamColor}>{finishedOrder}</FinishedOrderDiv>
                                    )}
                                </InfoBarItem>
                            ) : null}
                        </PlayerInfoLabel>
                    </PlayerInfoDiv>
                </PlayerInfoWrapper>
                {alignment !== Alignment.TOP && hand}
            </SeatDiv>
        );
    }
}
