import React, { useState } from "react";
import styled from "styled-components";
import { ApiClient } from "../../client/ApiClient";
import { DmBlindAiAssessment, DmPlayState, DmTradeAssessment, ExitOrder } from "../../client/server-types-python";
import { NowplayState, ReplayState } from "../../model/ReplayState";
import {
    getAiAssessmentExitFirstProbs,
    getAiAssessmentSortedActions,
    getAiAssessmentSortedChildStates,
    getAiAssessmentSortedExitOrderProbs,
    getAiAssessmentSortedTreeSearchValueEstimates,
    getAiAssessmentSortedTreeSearchVisitCounts,
} from "../../model/AiAssessmentUtil";
import theme from "../../theme";
import { TableContext } from "../TableContext";
import { PosId } from "../../client/basic-types";
import { Card } from "./Card";
import { CardDetails } from "../../model/CardDetails";

interface AiAssessmentPanelProps {
    apiClient: ApiClient;
    tableId: string;
    replayState: ReplayState;
    nowplayState: NowplayState;
    playState: DmPlayState;
}

const PanelContainer = styled.div<{ $isNSTurn: boolean }>`
    width: 250px;
    height: 530px;
    background-color: ${(props) => (props.$isNSTurn ? theme.colors.ns_color_light : theme.colors.ew_color_light)};
    overflow: auto;
    padding: 10px;
    box-sizing: border-box;
`;

const AssessmentButton = styled.button<{ $isNSTurn: boolean }>`
    background-color: ${(props) => (props.$isNSTurn ? theme.colors.ns_color : theme.colors.ew_color)};
    color: ${theme.colors.white_text};
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;

    &:hover {
        background-color: ${(props) => (props.$isNSTurn ? theme.colors.ns_color_light : theme.colors.ew_color_light)};
        color: ${theme.colors.black_text};
    }

    &:disabled {
        background-color: ${theme.colors.primary_dull};
        color: ${theme.colors.white_text_dull};
        cursor: not-allowed;
    }
`;

const LoadingMessage = styled.div`
    color: ${theme.colors.black_text};
    font-style: italic;
    margin-top: 10px;
`;

const ErrorMessage = styled.div`
    color: ${theme.colors.action_negative};
    font-weight: bold;
    margin-top: 10px;
`;

const AssessmentResults = styled.div`
    color: ${theme.colors.black_text};
    font-size: 12px;

    h3 {
        margin-top: 0;
        color: ${theme.colors.black_text};
    }

    p {
        margin: 5px 0;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 10px;
    }

    th,
    td {
        border: 1px solid black;
        padding: 5px;
        text-align: left;
    }

    th {
        font-weight: bold;
    }
`;

// Add this styled component near the other styled components
const PossibleStateButton = styled.button<{ $isNSTurn: boolean }>`
    background-color: ${(props) => (props.$isNSTurn ? theme.colors.ns_color : theme.colors.ew_color)};
    color: ${theme.colors.white_text};
    border: none;
    padding: 5px 10px;
    margin: 2px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 12px;

    &:hover {
        background-color: ${(props) => (props.$isNSTurn ? theme.colors.ns_color_light : theme.colors.ew_color_light)};
        color: ${theme.colors.black_text};
    }
`;

export const AiAssessmentPanel: React.FC<AiAssessmentPanelProps> = ({ apiClient, tableId, replayState, nowplayState, playState }) => {
    const tableContext = React.useContext(TableContext)!;
    const [assessment, setAssessment] = useState<DmBlindAiAssessment | null>(null);
    const [tradeAssessment, setTradeAssessment] = useState<DmTradeAssessment | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const notAnyonesTurn = playState.is_engine_turn || playState.is_hand_over;
    const tradePhase = playState.is_trade_phase;

    const handIndex = tableContext.gameplayContext!.displayedGameHandIndex;
    const moveIndex = tableContext.gameplayContext!.displayedHistoryIndex;
    const handleGetAssessment = async () => {
        setLoading(true);
        setError(null);

        try {
            const result = await apiClient.getBlindAiAssessment(tableId, handIndex, moveIndex, false);
            if (result instanceof Error) {
                throw result;
            }
            setAssessment(result);
            setTradeAssessment(null);
        } catch (err) {
            setError(err instanceof Error ? err.message : "An unknown error occurred");
        } finally {
            setLoading(false);
        }
    };
    const handleGetTradeAssessment = async () => {
        setLoading(true);
        setError(null);
        try {
            const result = await apiClient.getTradeAssessment(tableId, handIndex, moveIndex);
            if (result instanceof Error) {
                throw result;
            }
            setTradeAssessment(result);
            setAssessment(null);
        } catch (err) {
            setError(err instanceof Error ? err.message : "An unknown error occurred");
        } finally {
            setLoading(false);
        }
    };

    const sortedActions = assessment ? getAiAssessmentSortedActions(assessment) : [];
    const topSortedActions = sortedActions.slice(0, 5);
    const sortedChildStates = assessment ? getAiAssessmentSortedChildStates(assessment) : [];
    const topSortedChildStates = sortedChildStates.slice(0, 5);

    const sortedTreeSearchVisitCounts = assessment ? getAiAssessmentSortedTreeSearchVisitCounts(assessment) : [];
    const topSortedTreeSearchVisitCounts = sortedTreeSearchVisitCounts.slice(0, 5);

    const sortedTreeSearchValueEstimates = assessment ? getAiAssessmentSortedTreeSearchValueEstimates(assessment) : [];
    const topSortedTreeSearchValueEstimates = sortedTreeSearchValueEstimates.slice(0, 5);
    const isNSTurn = playState.active_player % 2 === 0;

    const filteredTopSortedActions = topSortedActions.filter((action) => Number((action.count * 100).toFixed(1)) !== 0);

    const playerNames = tableContext.game.participants.map((player) => (player?.user ? player.user.display_name : player?.bot_name));

    const trickWinnerProbs = assessment?.trick_winner_probs;
    const nextNonpasserProbs = assessment?.next_nonpasser_probs;
    const pointsAttributed = assessment?.points_attributed;

    const exitOrderProbs = assessment?.exit_order_probs;
    const topSortedExitOrderProbs = exitOrderProbs ? getAiAssessmentSortedExitOrderProbs(assessment).slice(0, 10) : [];
    const exitFirstProbs = exitOrderProbs ? getAiAssessmentExitFirstProbs(assessment) : [];

    const possibleStates = assessment?.possible_states;

    return (
        <PanelContainer $isNSTurn={isNSTurn}>
            {!tradePhase && (
                <>
                    <AssessmentButton onClick={handleGetAssessment} disabled={loading || notAnyonesTurn} $isNSTurn={isNSTurn}>
                        Assess
                    </AssessmentButton>
                </>
            )}
            {tradePhase && (
                <AssessmentButton onClick={() => handleGetTradeAssessment()} disabled={loading || notAnyonesTurn} $isNSTurn={isNSTurn}>
                    Assess Trade
                </AssessmentButton>
            )}

            {loading && <LoadingMessage>Loading...</LoadingMessage>}

            {error && <ErrorMessage>Error: {error}</ErrorMessage>}

            {assessment && (
                <AssessmentResults>
                    <p>Game EV: {assessment.state_value.toFixed(1)}</p>
                    {possibleStates && possibleStates.length > 0 && (
                        <div>
                            {possibleStates.map((state, index) => (
                                <PossibleStateButton key={index} $isNSTurn={isNSTurn} onClick={() => tableContext.setHypoState(state)}>
                                    {index}
                                </PossibleStateButton>
                            ))}
                        </div>
                    )}
                    <h4>Top Moves:</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Move</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTopSortedActions.map((action) => (
                                <tr key={action.move}>
                                    <td>{action.move}</td>
                                    <td>{(action.count * 100).toFixed(1)}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <h4>Top Child States:</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Move</th>
                                <th>Value +/-</th>
                            </tr>
                        </thead>
                        <tbody>
                            {topSortedChildStates.map((childState) => (
                                <tr key={childState.move}>
                                    <td>{childState.move}</td>
                                    <td>{childState.value.toFixed(1)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <h4>Top Tree Search Visits:</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Move</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {topSortedTreeSearchVisitCounts.map((visit) => (
                                <tr key={visit.move}>
                                    <td>{visit.move}</td>
                                    <td>{visit.count}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <h4>Top Tree Search Value Estimates:</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Move</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {topSortedTreeSearchValueEstimates.map((valueEstimate) => (
                                <tr key={valueEstimate.move}>
                                    <td>{valueEstimate.move}</td>
                                    <td>{valueEstimate.value.toFixed(1)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {trickWinnerProbs && nextNonpasserProbs && pointsAttributed && (
                        <>
                            <h4>Trick Winner, Next Non-passer, and Points Attributed:</h4>
                            <table className="min-w-full border-collapse border border-gray-300">
                                <thead>
                                    <tr>
                                        <th className="border border-gray-300 px-2 py-1">Stat</th>
                                        {[0, 1, 2, 3].map((pos) => (
                                            <th key={pos} className="border border-gray-300 px-2 py-1">
                                                Pos {pos}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="border border-gray-300 px-2 py-1 font-medium">Trick Winner</td>
                                        {trickWinnerProbs.map((prob, i) => (
                                            <td key={i} className="border border-gray-300 px-2 py-1 text-right">
                                                {(100 * prob).toFixed(1)}%
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 px-2 py-1 font-medium">Next Play</td>
                                        {nextNonpasserProbs.map((prob, i) => (
                                            <td key={i} className="border border-gray-300 px-2 py-1 text-right">
                                                {(100 * prob).toFixed(1)}%
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 px-2 py-1 font-medium">Points Earned</td>
                                        {pointsAttributed.map((points, i) => (
                                            <td key={i} className="border border-gray-300 px-2 py-1 text-right">
                                                {points.toFixed(1)}
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </>
                    )}
                    {exitFirstProbs && (
                        <>
                            <h4>Exit First:</h4>
                            <table>
                                <thead>
                                    <tr>
                                        <th>First</th>
                                        <th>Probability</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {exitFirstProbs.map((prob, i) => (
                                        <tr key={i}>
                                            <td>{i}</td>
                                            <td>{(100 * prob).toFixed(1)}%</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                    {topSortedExitOrderProbs && (
                        <>
                            <h4>Top Exit Orders:</h4>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Order</th>
                                        <th>Probability</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {topSortedExitOrderProbs.map((order) => (
                                        <tr key={order.order}>
                                            <td>{ExitOrder[order.order].substring(1)}</td>
                                            <td>{(100 * order.prob).toFixed(1)}%</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </AssessmentResults>
            )}
            {tradeAssessment && (
                <AssessmentResults>
                    {playerNames.map((playerName, index) => (
                        <p>
                            {playerName}:<br />
                            {playState.positions[index as PosId].hand_cards
                                .map((cardId) => CardDetails.getCardDetails(cardId))
                                .sort((a, b) => {
                                    if (a === null) {
                                        return 1;
                                    }
                                    if (b === null) {
                                        return -1;
                                    }
                                    return a.sortOrder - b.sortOrder;
                                })
                                .map((details) => (
                                    <Card cardDetails={details} textOnly={true} />
                                ))}
                            <table>
                                <tbody>
                                    {tradeAssessment.top_slates_by_position[index as PosId].map((assessedSlate, slateIndex) => (
                                        <tr key={"slaterow-" + index + "-" + slateIndex}>
                                            <td>{assessedSlate.rating.toFixed(1)}</td>
                                            <td>
                                                {assessedSlate.trade_slate.map((cardId) => (
                                                    <Card cardDetails={CardDetails.getCardDetails(cardId)} textOnly={true} />
                                                ))}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </p>
                    ))}
                </AssessmentResults>
            )}
        </PanelContainer>
    );
};
