import { useCallback, useEffect, useMemo, useState } from "react";
import { ApiClient } from "./client/ApiClient";
import { Login } from "./components/Login";
import { WebSocketClient } from "./client/WebSocketClient";
import { UserContext } from "./contexts/UserContext";
import { NavBar } from "./components/NavBar";
import { DmAuthResponse, DmGame, DmLobbyEvent, DmLobbyEventType, DmTable, DmUser, TableStatus } from "./client/server-types-python";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import { TablePage } from "./pages/TablePage";
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { UserSettingsPage } from "./pages/UserSettingsPage";
import { setVolume } from "./misc/SoundPlayer";
import { AboutPage } from "./pages/AboutPage";
import { defaultUiSettings, UiSettings, UiSettingsContext } from "./model/UiSettings";
import { LobbyPage } from "./pages/LobbyPage";
import { LobbyContext } from "./contexts/LobbyContext";
import { prependTableToTablesState } from "./client/util";

function App() {
    return (
        <BrowserRouter>
            <AppContent />
        </BrowserRouter>
    );
}

function AppContent() {
    const navigate = useNavigate();
    const base_url = process.env.REACT_APP_API_BASE_URL;
    if (base_url === undefined) {
        throw new Error("no REACT_APP_API_BASE_URL");
    }
    const apiClient = useMemo(() => new ApiClient(base_url), [base_url]);
    const localStorageUserId = localStorage.getItem("userId");
    const localStorageUserEmail = localStorage.getItem("userEmail");
    const localStorageUserDisplayName = localStorage.getItem("userDisplayName");
    const localStorageUserAvatar = parseInt(localStorage.getItem("userAvatar") ?? "0");
    const localStorageUserPrivilegeLevel = parseInt(localStorage.getItem("userPrivilegeLevel") ?? "0");
    const [user, setUser] = useState<DmUser | null>(
        localStorageUserId && localStorageUserEmail
            ? {
                  id: localStorageUserId,
                  email: localStorageUserEmail,
                  display_name: localStorageUserDisplayName ?? "",
                  avatar: localStorageUserAvatar,
                  privilege_level: localStorageUserPrivilegeLevel as 0 | 1 | 2,
              }
            : null
    );
    const [verifyingStoredUser, setVerifyingStoredUser] = useState(!!(localStorageUserId && localStorageUserEmail));
    const [tablesState, setTablesState] = useState<DmTable[]>([]);
    // TODO: should we do something more with this state? It's a timestamp - if it takes too long we could try again.
    const [initialLoadInProgressSince, setInitialLoadInProgressSince] = useState<number | null>(Date.now());
    const [uiSettings, setUiSettings] = useState<UiSettings>(defaultUiSettings);

    const setGame = useCallback(
        (game: DmGame, tableId: string) => {
            setTablesState((prevTablesState) => prevTablesState.map((table) => (table.id === tableId ? { ...table, game: game } : table)));
        },
        [setTablesState]
    );

    const [webSocketClient, setWebSocketClient] = useState<WebSocketClient | null>(null);

    // Verify the stored user from local storage. If this fails, clear the stored user.
    useEffect(() => {
        if (verifyingStoredUser) {
            const response = apiClient.verifyToken();
            response.then((response) => {
                if (response instanceof Error) {
                    localStorage.removeItem("userId");
                    localStorage.removeItem("userEmail");
                    localStorage.removeItem("userDisplayName");
                    localStorage.removeItem("userAvatar");
                    localStorage.removeItem("userPrivilegeLevel");
                    localStorage.removeItem("jwt");
                    setVerifyingStoredUser(false);
                    setUser(null);
                } else {
                    setVerifyingStoredUser(false);
                    setUser(response);
                }
            });
        }
    }, [user, verifyingStoredUser, apiClient]);

    // Once we have a verified user, set up the websocket client
    useEffect(() => {
        const jwt = localStorage.getItem("jwt");
        if (verifyingStoredUser || user === null || jwt === null) {
            setWebSocketClient(null);
            return;
        }

        const onConnect = () => {
            // fetch lobby info
            apiClient.listTables().then((tables) => {
                if (tables instanceof Error) {
                    console.error(tables);
                    return;
                }
                setTablesState(tables);
                setInitialLoadInProgressSince(null);
            });
        };

        const onLobbyEvent = (event: DmLobbyEvent) => {
            if (event.type === DmLobbyEventType.TABLE_CREATED) {
                setTablesState((prevTablesState) => prependTableToTablesState(prevTablesState, event.table));
            } else if (event.type === DmLobbyEventType.TABLE_CLOSED) {
                setTablesState((prevTablesState) => prevTablesState.filter((table) => table.id !== event.table.id));
            } else if (event.type === DmLobbyEventType.TABLE_UPDATED) {
                setTablesState((prevTablesState) => prevTablesState.map((table) => (table.id === event.table.id ? event.table : table)));
            }
        };

        const client = new WebSocketClient(base_url, jwt, onLobbyEvent, onConnect);

        setWebSocketClient(client);

        // Clean up function
        return () => {
            client.disconnect();
        };
    }, [user, verifyingStoredUser, apiClient, setGame, base_url]);

    // Set volume based on user setting
    useEffect(() => {
        if (user === null || user.user_settings?.volume === undefined) {
            return;
        }
        const volume = user.user_settings.volume;
        setVolume(volume / 4);
    }, [user]);

    const createTable = () => {
        const response = apiClient.newTable();
        response.then((responseTable) => {
            if (responseTable instanceof Error) {
                return undefined;
            }
            setTablesState((prevTablesState) => prependTableToTablesState(prevTablesState, responseTable));
            navigate(`/table/${responseTable.id}`);
        });
    };

    const createBotTable = () => {
        if (user === null) {
            return;
        }
        for (const table of tablesState) {
            if (table.status === TableStatus.LIMBO) {
                continue;
            }
            let hasThisUser = false;
            let hasOtherUser = false;
            for (const participant of table.game.participants) {
                if (participant !== null && participant.user !== null) {
                    if (participant.user.id === user.id) {
                        hasThisUser = true;
                    } else {
                        hasOtherUser = true;
                        break;
                    }
                } else if (participant === null && table.status === TableStatus.FINISHED) {
                    hasOtherUser = true;
                    break;
                }
            }
            if (hasThisUser && !hasOtherUser) {
                navigate(`/table/${table.id}`);
                return;
            }
        }
        const response = apiClient.newTable();
        response.then((responseTable) => {
            if (responseTable instanceof Error) {
                return undefined;
            }
            setTablesState((prevTablesState) => prependTableToTablesState(prevTablesState, responseTable));
            navigate(`/table/${responseTable.id}`);
            apiClient.addBot(responseTable.id, 1);
            apiClient.addBot(responseTable.id, 2);
            apiClient.addBot(responseTable.id, 3);
        });
    };

    const joinTable = (tableId: string, seat?: number) => {
        apiClient.joinTable(tableId, seat);
        navigate(`/table/${tableId}`);
    };

    const setLoggedInCallback = (dmAuthResponse: DmAuthResponse): void => {
        localStorage.setItem("userDisplayName", dmAuthResponse.user.display_name);
        localStorage.setItem("userAvatar", `${dmAuthResponse.user.avatar}`);
        localStorage.setItem("userId", `${dmAuthResponse.user.id}`);
        localStorage.setItem("userEmail", dmAuthResponse.user.email);
        localStorage.setItem("userPrivilegeLevel", `${dmAuthResponse.user.privilege_level}`);
        localStorage.setItem("jwt", dmAuthResponse.access_token);
        setUser(dmAuthResponse.user);
    };

    const updateUser = (user: DmUser): void => {
        localStorage.setItem("userDisplayName", user.display_name);
        setUser(user);
    };

    const logOut = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("userDisplayName");
        localStorage.removeItem("userAvatar");
        localStorage.removeItem("userPrivilegeLevel");
        localStorage.removeItem("jwt");
        setUser(null);
    };

    return (
        <ThemeProvider theme={theme}>
            <UserContext.Provider value={user ? { user, logoutFunc: logOut } : null}>
                <UiSettingsContext.Provider value={{ uiSettings, setUiSettings }}>
                    <LobbyContext.Provider value={{ tablesState }}>
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    user !== null ? (
                                        <>
                                            <LobbyPage
                                                initialLoadInProgressSince={initialLoadInProgressSince}
                                                tablesState={tablesState}
                                                joinTable={joinTable}
                                                createTable={createTable}
                                                createBotTable={createBotTable}
                                            ></LobbyPage>
                                        </>
                                    ) : (
                                        <>
                                            <NavBar />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "100%",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    paddingTop: "60px", // Add top padding to account for NavBar height
                                                }}
                                            >
                                                <Login
                                                    apiClient={apiClient}
                                                    setLoggedInCallback={setLoggedInCallback}
                                                    resetPasswordPageData={null}
                                                />
                                            </div>
                                        </>
                                    )
                                }
                            ></Route>
                            <Route
                                path="/table/:tableId"
                                element={
                                    user ? (
                                        <>
                                            <TablePage
                                                initialLoadInProgressSince={initialLoadInProgressSince}
                                                tablesState={tablesState}
                                                apiClient={apiClient}
                                                webSocketClient={webSocketClient}
                                                setGame={setGame}
                                                joinTable={joinTable}
                                            ></TablePage>
                                        </>
                                    ) : (
                                        <Navigate to="/" replace />
                                    )
                                }
                            ></Route>
                            <Route
                                path="/new_password"
                                element={
                                    <ResetPasswordPage apiClient={apiClient} setLoggedInCallback={setLoggedInCallback}></ResetPasswordPage>
                                }
                            ></Route>
                            <Route
                                path="/settings"
                                element={
                                    user ? (
                                        <UserSettingsPage apiClient={apiClient} user={user} updateUser={updateUser}></UserSettingsPage>
                                    ) : (
                                        <Navigate to="/" replace />
                                    )
                                }
                            ></Route>
                            <Route path="/about" element={<AboutPage />}></Route>
                            <Route path="*" element={<Navigate to="/" replace />} />
                        </Routes>
                    </LobbyContext.Provider>
                </UiSettingsContext.Provider>
            </UserContext.Provider>
        </ThemeProvider>
    );
}

export default App;
