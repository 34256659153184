import styled from "styled-components";
import React from "react";

export const TooltipWrapper = styled.div`
    position: relative;
    display: inline-flex;
    width: fit-content;

    &:hover > div {
        visibility: visible;
        opacity: 1;
        transition-delay: 0.5s;
    }
`;

export const TooltipContent = styled.div`
    visibility: hidden;
    position: fixed;
    padding: 8px 8px;
    background-color: ${(props) => props.theme.colors.tooltip_background};
    color: white;
    border-radius: 3px;
    font-size: 14px;
    white-space: pre-line;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.2s, visibility 0s linear 0s;
    transition-delay: 0s;
`;

interface TooltipProps {
    content: React.ReactNode;
    children: React.ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
    const [position, setPosition] = React.useState({ top: 0, left: 0 });
    const wrapperRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const updatePosition = () => {
            if (wrapperRef.current) {
                const rect = wrapperRef.current.getBoundingClientRect();
                setPosition({
                    top: rect.bottom,
                    left: rect.left + rect.width * 0.9,
                });
            }
        };

        updatePosition();
        window.addEventListener("resize", updatePosition);
        return () => window.removeEventListener("resize", updatePosition);
    }, []);

    return (
        <TooltipWrapper ref={wrapperRef}>
            {children}
            <TooltipContent style={{ top: `${position.top}px`, left: `${position.left}px` }}>{content}</TooltipContent>
        </TooltipWrapper>
    );
};
