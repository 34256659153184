import { DmBlindAiAssessment, DmOmniscientAiAssessment, ExitOrder } from "../client/server-types-python";

const ACTION_IDS_TO_STRINGS = [
    "(pass)",
    "Phoenix",
    "Dragon",
    "Dog",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "T",
    "J",
    "Q",
    "K",
    "A",
    "22",
    "33",
    "44",
    "55",
    "66",
    "77",
    "88",
    "99",
    "TT",
    "JJ",
    "QQ",
    "KK",
    "AA",
    "222",
    "333",
    "444",
    "555",
    "666",
    "777",
    "888",
    "999",
    "TTT",
    "JJJ",
    "QQQ",
    "KKK",
    "AAA",
    "2222",
    "3333",
    "4444",
    "5555",
    "6666",
    "7777",
    "8888",
    "9999",
    "TTTT",
    "JJJJ",
    "QQQQ",
    "KKKK",
    "AAAA",
    "22233",
    "22244",
    "22255",
    "22266",
    "22277",
    "22288",
    "22299",
    "222TT",
    "222JJ",
    "222QQ",
    "222KK",
    "222AA",
    "33322",
    "33344",
    "33355",
    "33366",
    "33377",
    "33388",
    "33399",
    "333TT",
    "333JJ",
    "333QQ",
    "333KK",
    "333AA",
    "44422",
    "44433",
    "44455",
    "44466",
    "44477",
    "44488",
    "44499",
    "444TT",
    "444JJ",
    "444QQ",
    "444KK",
    "444AA",
    "55522",
    "55533",
    "55544",
    "55566",
    "55577",
    "55588",
    "55599",
    "555TT",
    "555JJ",
    "555QQ",
    "555KK",
    "555AA",
    "66622",
    "66633",
    "66644",
    "66655",
    "66677",
    "66688",
    "66699",
    "666TT",
    "666JJ",
    "666QQ",
    "666KK",
    "666AA",
    "77722",
    "77733",
    "77744",
    "77755",
    "77766",
    "77788",
    "77799",
    "777TT",
    "777JJ",
    "777QQ",
    "777KK",
    "777AA",
    "88822",
    "88833",
    "88844",
    "88855",
    "88866",
    "88877",
    "88899",
    "888TT",
    "888JJ",
    "888QQ",
    "888KK",
    "888AA",
    "99922",
    "99933",
    "99944",
    "99955",
    "99966",
    "99977",
    "99988",
    "999TT",
    "999JJ",
    "999QQ",
    "999KK",
    "999AA",
    "TTT22",
    "TTT33",
    "TTT44",
    "TTT55",
    "TTT66",
    "TTT77",
    "TTT88",
    "TTT99",
    "TTTJJ",
    "TTTQQ",
    "TTTKK",
    "TTTAA",
    "JJJ22",
    "JJJ33",
    "JJJ44",
    "JJJ55",
    "JJJ66",
    "JJJ77",
    "JJJ88",
    "JJJ99",
    "JJJTT",
    "JJJQQ",
    "JJJKK",
    "JJJAA",
    "QQQ22",
    "QQQ33",
    "QQQ44",
    "QQQ55",
    "QQQ66",
    "QQQ77",
    "QQQ88",
    "QQQ99",
    "QQQTT",
    "QQQJJ",
    "QQQKK",
    "QQQAA",
    "KKK22",
    "KKK33",
    "KKK44",
    "KKK55",
    "KKK66",
    "KKK77",
    "KKK88",
    "KKK99",
    "KKKTT",
    "KKKJJ",
    "KKKQQ",
    "KKKAA",
    "AAA22",
    "AAA33",
    "AAA44",
    "AAA55",
    "AAA66",
    "AAA77",
    "AAA88",
    "AAA99",
    "AAATT",
    "AAAJJ",
    "AAAQQ",
    "AAAKK",
    "2233", // 2_STAIR_3
    "3344", // 2_STAIR_4
    "4455", // 2_STAIR_5
    "5566", // 2_STAIR_6
    "6677", // 2_STAIR_7
    "7788", // 2_STAIR_8
    "8899", // 2_STAIR_9
    "99TT", // 2_STAIR_T
    "TTJJ", // 2_STAIR_J
    "JJQQ", // 2_STAIR_Q
    "QQKK", // 2_STAIR_K
    "KKAA", // 2_STAIR_A
    "223344", // 3_STAIR_4
    "334455", // 3_STAIR_5
    "445566", // 3_STAIR_6
    "556677", // 3_STAIR_7
    "667788", // 3_STAIR_8
    "778899", // 3_STAIR_9
    "8899TT", // 3_STAIR_T
    "99TTJJ", // 3_STAIR_J
    "TTJJQQ", // 3_STAIR_Q
    "JJQQKK", // 3_STAIR_K
    "QQKKAA", // 3_STAIR_A
    "22...55", // 4_STAIR_5
    "33...66", // 4_STAIR_6
    "44...77", // 4_STAIR_7
    "55...88", // 4_STAIR_8
    "66...99", // 4_STAIR_9
    "77...TT", // 4_STAIR_T
    "88...JJ", // 4_STAIR_J
    "99...QQ", // 4_STAIR_Q
    "TT...KK", // 4_STAIR_K
    "JJ...AA", // 4_STAIR_A
    "22...66", // 5_STAIR_6
    "33...77", // 5_STAIR_7
    "44...88", // 5_STAIR_8
    "55...99", // 5_STAIR_9
    "66...TT", // 5_STAIR_T
    "77...JJ", // 5_STAIR_J
    "88...QQ", // 5_STAIR_Q
    "99...KK", // 5_STAIR_K
    "TT...AA", // 5_STAIR_A
    "22...77", // 6_STAIR_7
    "33...88", // 6_STAIR_8
    "44...99", // 6_STAIR_9
    "55...TT", // 6_STAIR_T
    "66...JJ", // 6_STAIR_J
    "77...QQ", // 6_STAIR_Q
    "88...KK", // 6_STAIR_K
    "99...AA", // 6_STAIR_A
    "22...88", // 7_STAIR_8
    "33...99", // 7_STAIR_9
    "44...TT", // 7_STAIR_T
    "55...JJ", // 7_STAIR_J
    "66...QQ", // 7_STAIR_Q
    "77...KK", // 7_STAIR_K
    "88...AA", // 7_STAIR_A
    "12345", // 5_STRAIGHT_5
    "23456", // 5_STRAIGHT_6
    "34567", // 5_STRAIGHT_7
    "45678", // 5_STRAIGHT_8
    "56789", // 5_STRAIGHT_9
    "6789T", // 5_STRAIGHT_T
    "789TJ", // 5_STRAIGHT_J
    "89TJQ", // 5_STRAIGHT_Q
    "9TJQK", // 5_STRAIGHT_K
    "TJQKA", // 5_STRAIGHT_A
    "123456", // 6_STRAIGHT_6
    "234567", // 6_STRAIGHT_7
    "345678", // 6_STRAIGHT_8
    "456789", // 6_STRAIGHT_9
    "56789T", // 6_STRAIGHT_T
    "6789TJ", // 6_STRAIGHT_J
    "789TJQ", // 6_STRAIGHT_Q
    "89TJQK", // 6_STRAIGHT_K
    "9TJQKA", // 6_STRAIGHT_A
    "1234567", // 7_STRAIGHT_7
    "2345678", // 7_STRAIGHT_8
    "3456789", // 7_STRAIGHT_9
    "456789T", // 7_STRAIGHT_T
    "56789TJ", // 7_STRAIGHT_J
    "6789TJQ", // 7_STRAIGHT_Q
    "789TJQK", // 7_STRAIGHT_K
    "89TJQKA", // 7_STRAIGHT_A
    "1...8", // 8_STRAIGHT_8
    "2...9", // 8_STRAIGHT_9
    "3...T", // 8_STRAIGHT_T
    "4...J", // 8_STRAIGHT_J
    "5...Q", // 8_STRAIGHT_Q
    "6...K", // 8_STRAIGHT_K
    "7...A", // 8_STRAIGHT_A
    "1...9", // 9_STRAIGHT_9
    "2...T", // 9_STRAIGHT_T
    "3...J", // 9_STRAIGHT_J
    "4...Q", // 9_STRAIGHT_Q
    "5...K", // 9_STRAIGHT_K
    "6...A", // 9_STRAIGHT_A
    "1...T",
    "2...J",
    "3...Q",
    "4...K",
    "5...A",
    "1...J",
    "2...Q",
    "3...K",
    "4...A",
    "1...Q",
    "2...K",
    "3...A",
    "1...K",
    "2...A",
    "1...A",
    "SF bomb",
    "Dragon: left",
    "Dragon: right",
    "Wish: 2",
    "Wish: 3",
    "Wish: 4",
    "Wish: 5",
    "Wish: 6",
    "Wish: 7",
    "Wish: 8",
    "Wish: 9",
    "Wish: T",
    "Wish: J",
    "Wish: Q",
    "Wish: K",
    "Wish: A",
    "Tichu!",
];

export const getAiAssessmentSortedActions = (assessment: DmOmniscientAiAssessment | DmBlindAiAssessment) => {
    return Object.entries(assessment.move_weights)
        .sort((a, b) => b[1] - a[1])
        .map(([move, count]) => ({ move: ACTION_IDS_TO_STRINGS[parseInt(move)], count }));
};

export const getAiAssessmentSortedChildStates = (assessment: DmOmniscientAiAssessment | DmBlindAiAssessment) => {
    return Object.entries(assessment.child_state_values)
        .sort((a, b) => b[1] - a[1])
        .map(([move, value]) => ({ move: ACTION_IDS_TO_STRINGS[parseInt(move)], value: value - assessment.state_value }));
};

export const getAiAssessmentSortedTreeSearchVisitCounts = (assessment: DmBlindAiAssessment) => {
    return Object.entries(assessment.tree_search_visits)
        .sort((a, b) => b[1] - a[1])
        .map(([move, count]) => ({ move: ACTION_IDS_TO_STRINGS[parseInt(move)], count }));
};

export const getAiAssessmentSortedTreeSearchValueEstimates = (assessment: DmBlindAiAssessment) => {
    return Object.entries(assessment.tree_search_value_estimates)
        .filter(([move, value]) => assessment.tree_search_visits[parseInt(move)] > 0)
        .sort((a, b) => b[1] - a[1])
        .map(([move, value]) => ({ move: ACTION_IDS_TO_STRINGS[parseInt(move)], value }));
};

export const getAiAssessmentSortedExitOrderProbs = (assessment: DmBlindAiAssessment) => {
    return Object.entries(assessment.exit_order_probs)
        .sort((a, b) => b[1] - a[1])
        .map(([order, prob]) => ({ order: ExitOrder.from_value(parseInt(order)), prob }));
};

export const getAiAssessmentExitFirstProbs = (assessment: DmBlindAiAssessment) => {
    const result = [0, 0, 0, 0];
    for (let i = 0; i < 20; i++) {
        const prob = assessment.exit_order_probs[i];
        const exitOrderDigits = ExitOrder[ExitOrder.from_value(i)].substring(1);
        const firstDigit = parseInt(exitOrderDigits[0]);
        result[firstDigit] += prob;
    }
    return result;
};
