import { useContext, useEffect, useRef } from "react";
import { CombinationType, DmGameParticipant, DmHistoryEntry, GameStatus } from "../../client/server-types-python";

import styled from "styled-components";
import { CardId, Quartet } from "../../client/basic-types";
import { CardDetails } from "../../model/CardDetails";
import { Card, RANKS } from "./Card";
import { getName } from "../../client/util";
import { getHandHistory } from "../../model/HandHistoryUtil";
import theme from "../../theme";
import { TableContext } from "../TableContext";

interface Theme {
    colors: typeof theme.colors;
}

interface HistoryPanelDivProps {
    $replayMode: boolean;
    theme: Theme;
}

const getBackgroundColor = (props: HistoryPanelDivProps) =>
    props.$replayMode ? props.theme.colors.replay_mode_light : props.theme.colors.primary_very_light;
const getScrollbarColor = (props: HistoryPanelDivProps) => (props.$replayMode ? props.theme.colors.primary : props.theme.colors.primary);
const getScrollbarHintColor = (props: HistoryPanelDivProps) =>
    props.$replayMode ? props.theme.colors.primary_hinted_white : props.theme.colors.primary_hinted_white;

const HistoryPanelDiv = styled.div<{ $replayMode: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 250px;
    min-height: 0px;
    margin: 0px 12px;
    font-size: 14px;
    padding: 0px;
    background-color: ${getBackgroundColor};
    scrollbar-color: ${getScrollbarColor} ${getScrollbarHintColor};
    border-radius: 5px;
    flex: 1; /* Take up remaining space */
`;

const ScrollableArea = styled.div`
    overflow-y: auto;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const ScrollableAreaInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 8px;
`;

const MoveItem = styled.div`
    text-align: left;
`;

const PlayerAttribution = styled.span`
    font-weight: bold;
`;

const Scoreboard = styled.table`
    margin: 16px 16px 0px 16px;
    padding: 4px;
    border: 1px dashed black;
    border-radius: 8px;
`;

const ScoreboardCell = styled.td`
    padding: 4px 8px;
    font-size: 14px;
    font-weight: bold;
    min-width: 40px;
`;

export type HistoryPanelProps = {
    nextHandCallback: () => void;
    cardsGiven: CardId[] | null;
    cardsReceived: CardId[] | null;
    showScoreModal: () => void;
};

export const HistoryPanel = ({ nextHandCallback, cardsGiven, cardsReceived, showScoreModal }: HistoryPanelProps): JSX.Element => {
    const tc = useContext(TableContext)!;
    const scrollableAreaRef = useRef<HTMLDivElement | null>(null);

    const game = tc.game;
    const replayState = tc.replayState;
    const nowplayState = tc.nowplayState;
    const handHistoryCache = tc.handHistoryCache;
    const minDisplayedHistoryIndex = tc.gameplayContext!.minDisplayedHistoryIndex;

    const handIndex = tc.replayState.replayModeEnabled ? tc.replayState.replayHandCursor : tc.nowplayState.nowplayHandCursor;
    const fullHandHistory = getHandHistory(game, handIndex, handHistoryCache) ?? [];
    const handHistory = fullHandHistory.slice(
        minDisplayedHistoryIndex,
        (replayState.replayModeEnabled ? replayState.replayMoveCursor : nowplayState.nowplayMoveCursor) + 1
    );

    const gameHandIndex = replayState.replayModeEnabled ? replayState.replayHandCursor : nowplayState.nowplayHandCursor;
    const moveIndex = handHistory.length - 1;
    const playState = handHistory[moveIndex].state;

    const playerNames = game.participants.map((player) => (player ? getName(player) : "(open seat)"));

    useEffect(() => {
        if (scrollableAreaRef.current) {
            scrollableAreaRef.current.scrollTop = scrollableAreaRef.current.scrollHeight;
        }
    }, [gameHandIndex, moveIndex, handHistory, game.participants]); // replace with the variables that change when new content is added

    if (!game || game.game_hands.length === 0) {
        return <HistoryPanelDiv $replayMode={replayState.replayModeEnabled}></HistoryPanelDiv>;
    }

    const renderHandHistory = (history: DmHistoryEntry[], players: Quartet<DmGameParticipant | null>) => {
        const moveHistory = history.map((entry) => entry.move);
        const numGrands = moveHistory.filter((move) => move?.combination.combination_type === CombinationType.GRAND_BET).length;
        let moveDivs = moveHistory.map((move, index) => {
            if (move === null) {
                return null;
            }
            const player = players[move.position];
            const playerText = player ? getName(player) : ["S", "W", "N", "E"][move.position];
            let moveContent = null;
            if (move.win_trick) {
                moveContent = "collects the trick";
            } else if (move.combination.combination_type === CombinationType.PASS) {
                moveContent = "pass";
            } else if (move.combination.combination_type === CombinationType.BET) {
                moveContent = "Tichu!";
            } else if (move.combination.combination_type === CombinationType.GRAND_BET) {
                moveContent = "Grand Tichu!";
            } else if (move.combination.combination_type === CombinationType.GIVE_DRAGON && move.give_dragon_pos !== null) {
                moveContent = "gives Dragon to " + getName(players[move.give_dragon_pos]);
            } else if (move.combination.combination_type === CombinationType.MAKE_WISH) {
                if (move.wish_rank !== null) {
                    moveContent = "wish for " + RANKS[move.wish_rank];
                } else {
                    moveContent = "(no wish)";
                }
            } else {
                moveContent = move.combination.cards.map((card, cardIndex) => (
                    <Card cardDetails={CardDetails.getCardDetails(card)} textOnly={true} key={"card." + cardIndex}></Card>
                ));
            }
            return (
                <MoveItem key={"move." + index}>
                    <PlayerAttribution>{playerText + ": "}</PlayerAttribution>
                    {moveContent}
                </MoveItem>
            );
        });

        moveDivs = moveDivs.filter((moveDiv) => moveDiv !== null);

        if (minDisplayedHistoryIndex === 0) {
            const cardExchangeDivs = [];

            if (cardsGiven !== null) {
                const gaveText = cardsReceived === null ? "You are giving: " : "You gave: ";
                cardExchangeDivs.push(
                    <MoveItem key={"cardsGiven"}>
                        <PlayerAttribution>{gaveText}</PlayerAttribution>
                        {cardsGiven.map((card, cardIndex) => (
                            <Card cardDetails={CardDetails.getCardDetails(card)} textOnly={true} key={"card." + cardIndex} />
                        ))}
                    </MoveItem>
                );
            }
            if (cardsReceived !== null) {
                cardExchangeDivs.push(
                    <MoveItem key={"cardsReceived"}>
                        <PlayerAttribution>{"You got: "}</PlayerAttribution>
                        {cardsReceived.map((card, cardIndex) => (
                            <Card cardDetails={CardDetails.getCardDetails(card)} textOnly={true} key={"card." + cardIndex} />
                        ))}
                    </MoveItem>
                );
            }

            // Insert cardExchangeDivs after the Grand Tichu bets
            moveDivs.splice(numGrands, 0, ...cardExchangeDivs);
        } else {
            // Replace the oldest line - which will always be "x collects the trick" or "x gives Dragon to y" with this.
            moveDivs[0] = (
                <MoveItem key={moveDivs[0]!.key} style={{ color: "#666", fontStyle: "italic" }}>
                    (previous tricks hidden)
                </MoveItem>
            );
        }

        return <ScrollableAreaInner>{moveDivs}</ScrollableAreaInner>;
    };

    const renderScoreboard = (playerNames: string[], nextHandCallback: () => void, replayMode: boolean, isGameOver: boolean) => {
        return (
            <>
                <Scoreboard>
                    <tbody>
                        <tr>
                            <ScoreboardCell>{playerNames[0] + " / " + playerNames[2]}</ScoreboardCell>
                            <ScoreboardCell>{playState.ns_score + " ◈"}</ScoreboardCell>
                        </tr>
                        <tr>
                            <ScoreboardCell>{playerNames[1] + " / " + playerNames[3]}</ScoreboardCell>
                            <ScoreboardCell>{playState.ew_score + " ◈"}</ScoreboardCell>
                        </tr>
                    </tbody>
                </Scoreboard>
                {replayMode ? (
                    <br />
                ) : isGameOver ? (
                    <button onClick={showScoreModal} className="nextHandButton easyButton">
                        {"View Final Scores"}
                    </button>
                ) : (
                    <button onClick={nextHandCallback} className="nextHandButton easyButton">
                        {"Start Next Hand"}
                    </button>
                )}
            </>
        );
    };

    const isGameOver =
        game.status === GameStatus.COMPLETE && game.game_hands.length - 1 === gameHandIndex && moveIndex === handHistory.length - 1;
    // game.status === GameStatus.COMPLETE && game.game_hands.length - 1 === gameHandIndex && moveIndex === handHistory.length - 1;

    return (
        <HistoryPanelDiv $replayMode={replayState.replayModeEnabled}>
            <ScrollableArea ref={scrollableAreaRef}>
                <ScrollableAreaInner>{renderHandHistory(handHistory, game.participants)}</ScrollableAreaInner>
            </ScrollableArea>
            {(playState.is_hand_over || isGameOver) &&
                renderScoreboard(playerNames, nextHandCallback, replayState.replayModeEnabled, isGameOver)}
        </HistoryPanelDiv>
    );
};
