import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HandAndScoreDisplay from "./HandAndScoreDisplay";
import { GameDropdown } from "./GameDropdown";
import { getHandHistory } from "../../model/HandHistoryUtil";
import { ReactComponent as LeftArrow } from "../../assets/arrow-left.svg";
import { ReactComponent as RightArrow } from "../../assets/arrow-right.svg";
import { TableContext } from "../TableContext";

interface ReplayBarProps {
    leaveTable: () => void;
    requestUndo: (hand: number, move: number) => void;
    requestAbort: () => void;
    showScoreModal: () => void;
}

const ReplayBarContainer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

const Slider = styled.input<{ $replayModeEnabled: boolean; $singleValue: boolean }>`
    width: 500px;
    height: 24px;
    -webkit-appearance: none;
    appearance: none;
    background: ${(props) => (props.$replayModeEnabled ? props.theme.colors.replay_mode_light : props.theme.colors.primary_very_light)};
    outline: none;
    border-radius: 12px;
    cursor: ${(props) => (props.$singleValue ? "default" : "pointer")};

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: ${(props) => (props.$singleValue ? "0" : "40px")};
        height: ${(props) => (props.$singleValue ? "0" : "24px")};
        background: ${(props) => (props.$replayModeEnabled ? props.theme.colors.primary_dark : props.theme.colors.primary_dark)};
        cursor: ${(props) => (props.$singleValue ? "default" : "grab")};
        border-radius: 12px;
    }

    &::-moz-range-thumb {
        width: ${(props) => (props.$singleValue ? "0" : "40px")};
        height: ${(props) => (props.$singleValue ? "0" : "24px")};
        background: ${(props) => (props.$replayModeEnabled ? props.theme.colors.primary_dark : props.theme.colors.primary_dark)};
        cursor: ${(props) => (props.$singleValue ? "default" : "grab")};
        border-radius: 12px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
`;

const ArrowButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
`;

const ArrowIcon = styled.svg`
    width: 36px;
    height: 36px;
    fill: ${(props) => props.theme.colors.primary_dark};
`;

export const ReplayBar: React.FC<ReplayBarProps> = ({ leaveTable, requestUndo, requestAbort, showScoreModal }) => {
    const tc = React.useContext(TableContext)!;
    const [sliderValue, setSliderValue] = useState(tc.replayState.replayMoveCursor);

    const minVal = tc.gameplayContext!.minDisplayedHistoryIndex;
    const maxVal = tc.gameplayContext!.maxDisplayedHistoryIndex;

    // If the maxVal is minVal, then the slider would only have one value. In this case we'll hide the slider thumb.
    const isSingleValue = maxVal === minVal;

    useEffect(() => {
        if (!tc.replayState.replayModeEnabled) {
            setSliderValue(maxVal);
        } else {
            setSliderValue(tc.replayState.replayMoveCursor);
        }
    }, [tc.replayState.replayModeEnabled, tc.replayState.replayMoveCursor, maxVal]);

    const handleStepLeft = () => {
        const newValue = Math.max(minVal, sliderValue - 1);
        setSliderValue(newValue);
        updateReplayState(newValue);
    };

    const handleStepRight = () => {
        const newValue = Math.min(maxVal, sliderValue + 1);
        setSliderValue(newValue);
        updateReplayState(newValue);
    };

    const updateReplayState = (newMoveCursor: number) => {
        const inReplay =
            newMoveCursor !== tc.nowplayState.nowplayMoveCursor || tc.replayState.replayHandCursor !== tc.nowplayState.nowplayHandCursor;
        // If we are not already replaying a hand, the relevant hand is the current one.
        const newHandCursor = tc.replayState.replayModeEnabled ? tc.replayState.replayHandCursor : tc.nowplayState.nowplayHandCursor;
        tc.setReplayState((prevState) => ({
            ...prevState,
            replayModeEnabled: inReplay,
            replayMoveCursor: newMoveCursor,
            replayHandCursor: newHandCursor,
        }));
    };

    const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newMoveCursor = parseInt(event.target.value, 10);
        setSliderValue(newMoveCursor);
        updateReplayState(newMoveCursor);
    };

    const handCursor = tc.replayState.replayModeEnabled ? tc.replayState.replayHandCursor : tc.nowplayState.nowplayHandCursor;
    const handNumber = handCursor + 1;

    const game = tc.game;

    const handIndex = tc.replayState.replayModeEnabled ? tc.replayState.replayHandCursor : tc.nowplayState.nowplayHandCursor;
    const fullHandHistory = getHandHistory(game, handIndex, tc.handHistoryCache) ?? [];
    const handHistory = fullHandHistory.slice(
        0,
        (tc.replayState.replayModeEnabled ? tc.replayState.replayMoveCursor : tc.nowplayState.nowplayMoveCursor) + 1
    );

    const gameHandIndex = tc.replayState.replayModeEnabled ? tc.replayState.replayHandCursor : tc.nowplayState.nowplayHandCursor;
    const moveIndex = handHistory.length - 1;

    const undoMyMove = () => {
        let undoIndex = moveIndex - 1;
        while (undoIndex > 0) {
            const entry = handHistory[undoIndex];
            const state = entry.state;
            if (
                (state.active_player === tc.playerSeat && !state.is_engine_turn) ||
                state.is_trade_phase ||
                (state.grand_phase_state !== null && state.grand_phase_state.grand_bet_choices[tc.playerSeat] === null)
            ) {
                // found an index where player is active. rewind to here.
                break;
            }
            undoIndex--;
        }
        requestUndo(gameHandIndex, undoIndex);
    };

    const requestUndoFunc =
        tc.replayState.replayModeEnabled && tc.replayState.replayHandCursor === tc.game.game_hands.length - 1
            ? () => requestUndo(tc.replayState.replayHandCursor, tc.replayState.replayMoveCursor)
            : moveIndex > 0 && !tc.replayState.replayModeEnabled
            ? undoMyMove
            : undefined;

    let nsScore = 0,
        ewScore = 0;
    for (let i = 0; i <= tc.gameplayContext!.displayedGameHandIndex; i++) {
        if (i !== tc.gameplayContext!.displayedGameHandIndex || tc.gameplayContext!.displayedPlayState.is_hand_over) {
            nsScore += game.game_hands[i].play_state.ns_score;
            ewScore += game.game_hands[i].play_state.ew_score;
        }
    }

    return (
        <ReplayBarContainer>
            <HandAndScoreDisplay nsScore={nsScore} ewScore={ewScore} handNumber={handNumber} showScoreModal={showScoreModal} />
            <ButtonContainer>
                <ArrowButton onClick={handleStepLeft} disabled={sliderValue === 0}>
                    <ArrowIcon as={LeftArrow} />
                </ArrowButton>
                <Slider
                    $replayModeEnabled={tc.replayState.replayModeEnabled}
                    $singleValue={isSingleValue}
                    type="range"
                    min={minVal}
                    max={maxVal}
                    value={sliderValue}
                    onChange={handleSliderChange}
                />
                <ArrowButton onClick={handleStepRight} disabled={sliderValue === maxVal}>
                    <ArrowIcon as={RightArrow} />
                </ArrowButton>
            </ButtonContainer>
            <ButtonContainer>
                <GameDropdown
                    leaveTable={leaveTable}
                    requestAbort={requestAbort}
                    requestUndoFunc={requestUndoFunc}
                    gameStatus={game.status}
                />
            </ButtonContainer>
        </ReplayBarContainer>
    );
};
